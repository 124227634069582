<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
export default {
    async created() {
        window.addEventListener('resize', this.handleWindowResize)
        window.addEventListener('scroll', this.handleScroll)
    },

    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('scroll', this.handleScroll)
    },

    methods: {
        handleWindowResize() {
            // this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        },
        handleScroll() {
            // this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
        }
    },
}
</script>