export default {
    install(Vue) {
        Vue.prototype.$numberFormat = function (
            number,
            decimals,
            dec_point,
            thousands_sep
        ) {
            number = (number + "").replace(/[^0-9+\-Ee.]/g, "");
            var n = !isFinite(+number) ? 0 : +number,
                prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
                sep =
                    typeof thousands_sep === "undefined" ? "," : thousands_sep,
                dec = typeof dec_point === "undefined" ? "." : dec_point,
                s = "",
                toFixedFix = function (n, prec) {
                    var k = Math.pow(10, prec);
                    return "" + Math.round(n * k) / k;
                };
            // Fix for IE parseFloat(0.55).toFixed(0) = 0;
            s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
            if (s[0].length > 3) {
                s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
            }
            if ((s[1] || "").length < prec) {
                s[1] = s[1] || "";
                s[1] += new Array(prec - s[1].length + 1).join("0");
            }
            return s.join(dec);
        };

        Vue.prototype.$dateFormat = function (date) {
            let divider = "/";

            const theData = new Date(date);

            let day = theData.getDate();
            day = day < 10 ? `0${day}` : day;

            let month = theData.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;

            let year = theData.getFullYear();

            return `${day}${divider}${month}${divider}${year}`;
        };

        Vue.prototype.$dateTimeFormat = function (date) {
            const theData = new Date(date);

            let day = theData.getDate();
            day = day < 10 ? `0${day}` : day;

            let month = theData.getMonth() + 1;
            month = month < 10 ? `0${month}` : month;

            let year = theData.getFullYear();

            let hour = theData.getHours();
            hour = hour < 10 ? `0${hour}` : hour;

            let minute = theData.getMinutes();
            minute = minute < 10 ? `0${minute}` : minute;

            return `${day}/${month}/${year} ${hour}:${minute}`;
        };

        Vue.prototype.$timeFormat = function (date) {
            const theData = new Date(date);

            let hour = theData.getHours();
            hour = hour < 10 ? `0${hour}` : hour;

            let minute = theData.getMinutes();
            minute = minute < 10 ? `0${minute}` : minute;

            return `${hour}:${minute}`;
        };

        Vue.prototype.$timeout = function (ms) {
            return new Promise((resolve) => setTimeout(resolve, ms));
        };

        Vue.prototype.$copyToClipboard = function (text) {
            if (!navigator.clipboard) {
                fallbackCopyTextToClipboard(text);
                return;
            }

            navigator.clipboard.writeText(text).then(
                () => {
                    // console.log('Async: Copying to clipboard was successful!');
                    return true;
                },
                function (err) {
                    // console.error('Async: Could not copy text: ', err);
                    return false;
                }
            );
        };

        Vue.prototype.$isNumber = function (evt) {
            evt = evt ? evt : window.event;
            var charCode = evt.which ? evt.which : evt.keyCode;
            if (
                charCode > 31 &&
                (charCode < 48 || charCode > 57) &&
                charCode !== 46
            ) {
                evt.preventDefault();
            } else {
                return true;
            }
        };
    },
};
