import axios from "../../http/axios/index.js";

const supports = [];
supports.push({
    name: `LP Thai ARi `,
    imageUrl: `/supports/logo-thai-ari.png`,
});
supports.push({
    name: `Chula ARi `,
    imageUrl: `/supports/logo-chula-ari.png`,
    link: `https://www.chulaari.chula.ac.th`,
});
supports.push({
    name: `มหาวิทยาลัยราชภัฏลำปาง `,
    imageUrl: `/supports/logo-lpru-2560.png`,
    link: `https://www.lpru.ac.th/`,
});

/**
 * modules
 */
const modules = [];
modules.push({
    title: "แบบประเมินสุขภาพ",
    imageUrl: "/images/modules/assessment.png",
    path: "/assessment",
});
modules.push({
    title: "แหล่งความรู้สุขภาพ",
    imageUrl: "/images/modules/knowledge.png",
    path: "/knowledge",
});
modules.push({
    title: "ขอรับคำปรึกษา",
    imageUrl: "/images/modules/chat.png",
    path: "/help",
});
modules.push({
    title: "ร้านค้า/บริการ",
    imageUrl: "/images/modules/service.png",
    path: "/marketplace",
});
modules.push({
    title: "เบอร์โทรฉุกเฉิน",
    imageUrl: "/images/modules/advice.png",
    path: "/hotline",
});

// state
const state = {
    supports: supports,
    villages: undefined,

    showBottomNavigationBar: true,

    appointmentTimeOptions: undefined,

    modules,

    educations: undefined,
    occupations: undefined,
};

// getters
const getters = {
    supports: (state) => state.supports,
    villages: (state) => state.villages,
    showBottomNavigationBar: (state) => state.showBottomNavigationBar,

    // appointment
    appointmentTimeOptions: (state) => state.appointmentTimeOptions,

    // modules
    modules: (state) => state.modules,

    // educations
    educations: (state) => state.educations,

    // occupations
    occupations: (state) => state.occupations,
};

// mutations
const mutations = {
    SET_VILLAGES(state, villages) {
        state.villages = villages;
    },
    SET_SHOW_BOTTOM_NAVIGATION_BAR(state, active) {
        state.showBottomNavigationBar = active;
    },
    SET_APPOINTMENT_TIME_OPTIONS(state, list) {
        state.appointmentTimeOptions = list;
    },
    SET_EDUCATIONS(state, list) {
        state.educations = list;
    },
    SET_OCCUPATIONS(state, list) {
        state.occupations = list;
    },
};

// actions
const actions = {
    setVillages({ state, commit }, villages) {
        commit("SET_VILLAGES", villages);
    },
    setShowBottomNavigationBar({ commit }, active) {
        commit("SET_SHOW_BOTTOM_NAVIGATION_BAR", active);
    },

    getAppointmentTimeOptions({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/appointment/times")
                .then((response) => {
                    commit("SET_APPOINTMENT_TIME_OPTIONS", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    getVillages({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/villages")
                .then((response) => {
                    commit("SET_VILLAGES", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // educations
    getEducation({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/educations")
                .then((response) => {
                    commit("SET_EDUCATIONS", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    // occupations
    getOccupation({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/occupations")
                .then((response) => {
                    commit("SET_OCCUPATIONS", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
