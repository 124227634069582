import axios from "../../axios.js";
import jwt from "../../http/requests/auth/jwt/index.js";
import router from "../../router";

const TOKEN_KEY = "accessToken";

export default {
    loginJWT({ commit }, payload) {
        return new Promise((resolve, reject) => {
            jwt.login(payload)
                .then((response) => {
                    const res = response.data;
                    // console.log('response: ', res);

                    if (res.data) {
                        // Set accessToken
                        localStorage.setItem("accessToken", res.accessToken);

                        // Update user details
                        commit("SET_USER", res.data);
                        // commit('UPDATE_USER_INFO', response.data.user, { root: true })

                        // Set bearer token in axios
                        commit("SET_BEARER", res.accessToken);
                        commit("UPDATE_TOKEN", {
                            accessToken: res.accessToken,
                            tokenExpiry: res.expired,
                        });

                        // Navigate User to homepage
                        router.push(router.currentRoute.query.to || "/");
                        resolve(res);
                    } else {
                        reject({ message: "Wrong Email or Password" });
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    fetchAccessToken({ commit }) {
        return new Promise((resolve, reject) => {
            jwt.refreshToken()
                .then((response) => {
                    const res = response.data;

                    // Update accessToken in localStorage
                    localStorage.setItem("accessToken", res.accessToken);

                    // // Set bearer token in axios
                    commit("SET_BEARER", res.accessToken);
                    commit("UPDATE_TOKEN", {
                        accessToken: res.accessToken,
                        tokenExpiry: res.tokenExpired,
                    });

                    resolve(res.accessToken);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },

    logout({ commit }) {
        return new Promise((resolve, reject) => {
            jwt.logout()
                .then((response) => {
                    // Set bearer token in axios
                    commit("CLEAR_USER");

                    resolve(response);
                })
                .catch((error) => {
                    commit("CLEAR_USER");

                    reject(error);
                });
        });
    },

    async fetchUser({ commit }) {
        return new Promise((resolve, reject) => {
            const token = localStorage.getItem(TOKEN_KEY);
            if (token) {
                commit("SET_BEARER", token);
            }

            const user = localStorage.getItem("user");
            const responseFail = () => {
                commit("CLEAR_USER");
                reject(false);
            };

            if (!user && token) {
                jwt.getUser()
                    .then((response) => {
                        if (response.data) {
                            commit("SET_USER", response.data.data);
                            resolve(response.data);
                        } else {
                            responseFail();
                        }
                    })
                    .catch((error) => {
                        responseFail();
                    });
            } else {
                responseFail();
            }
        });
    },

    clearUser({ commit }) {
        commit("CLEAR_USER");
    },

    updateLoggedIn({ commit }, payload) {
        commit("UPDATE_LOGGED_IN", payload);
    },

    updateProfile({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/account/general", payload)
                .then((response) => {
                    commit("SET_USER", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateContact({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/account/contact", payload)
                .then((response) => {
                    commit("SET_USER", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
    updateEducation({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .put("/account/education", payload)
                .then((response) => {
                    commit("SET_USER", response.data.data);
                    resolve(response);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    },
};
