import axios from "../axios";

// state
const state = {};

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
    async setBMI({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(`/bmi`, payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
