import axios from "../../axios";

// state
const state = {
    list: [],
    meta: {
        current_page: 1,
    },

    filter: {
        page: 1,
        per_page: 10,
    },

    status: "",
};

// getters
const getters = {
    list: (state) => state.list,
    meta: (state) => state.meta,
    filter: (state) => state.filter,
    status: (state) => state.status,
};

// mutations
const mutations = {
    SET_LIST(state, list) {
        state.list = [...state.list, ...list];
    },
    RESET_LIST(state, list) {
        state.list = [];
    },
    SET_META(state, meta) {
        state.meta = { ...state.meta, ...meta };
    },
};

// actions
const actions = {
    async get({ commit }, filter) {
        if (filter.page == 1) {
            commit("RESET_LIST");
        }

        return new Promise((resolve, reject) => {
            axios
                .get("/hotline", { params: filter })
                .then((response) => {
                    const res = response.data;

                    commit("SET_LIST", res.data);
                    commit("SET_META", res.meta);
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
