// axios
import axios from "axios";

const baseURL = "/api";

export default axios.create({
    baseURL,
    // You can add your headers here

    // headers
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        "X-Requested-With": "XMLHttpRequest",
    },
});
