<template>
    <div id="app-front" class="container relative min-h-screen">
        <router-view />
        <BottomNavigationBar v-if="showBottomNavigationBar" />
    </div>
</template>

<script>
import jwt from '@/http/requests/auth/jwt/index.js'
import { mapGetters } from "vuex";
import BottomNavigationBar from '../components/BottomNavigationBar.vue'
export default {
    components: {
        BottomNavigationBar
    },
    computed: {
        ...mapGetters({
            isLogin: "auth/isLoggedIn",
            tokenExpiresAt: "auth/expiresAt",
        }),

        // isScrolling() {
        //     return this.$store.state.scrollY > 20;
        // }
    },
    data() {
        return {
            showBottomNavigationBar: true
        }
    },
    watch: {
        tokenExpiresAt() {
            this.countdownToken();
        }
    },
    async created() {
        jwt.init();

        window.addEventListener('resize', this.handleWindowResize)
        window.addEventListener('scroll', this.handleScroll)

        try {
            const user = await this.$store.dispatch('auth/fetchUser')
            // console.log('fetchUser: ok', user);
            if (user) {
                this.countdownToken();
            }
            else {
                this.$store.commit('auth/CLEAR_USER')
                // this.$router.push('/login')
            }

        } catch (error) {
            // console.log('fetchUser: error', error);
            this.$store.commit('auth/CLEAR_USER')
            // this.$router.push('/login')
        }

        this.$router.beforeEach((to, from, next) => {
            if (to.meta.hideBottomNavigationBar) {
                this.showBottomNavigationBar = false
            } else {
                this.showBottomNavigationBar = true
            }
            next()
        })
    },

    methods: {
        handleWindowResize() {
            // this.$store.commit('UPDATE_WINDOW_WIDTH', window.innerWidth)
        },
        handleScroll() {
            // this.$store.commit('UPDATE_WINDOW_SCROLL_Y', window.scrollY)
        },

        async countdownToken() {
            if (!this.isLogin || !this.tokenExpiresAt) return;
            const expireTokenDate = new Date(this.tokenExpiresAt)

            // const leeway = 5 * 60 * 60 * 1000 // 5 hours
            const leeway = 3 * 60 * 1000 // 3 minutes
            let expireTokenTime = expireTokenDate.getTime() - Date.now()
            clearTimeout(this.countdownTokenTime);

            // console.log('expireTokenDate...', expireTokenTime, leeway);

            if (expireTokenTime < 0) {
                // clerar token
                this.$store.commit('auth/CLEAR_USER')
                this.$router.push('/login')
                return false;
            }

            if (expireTokenTime < leeway) {
                const reconnect = await this.reconnectToken();
                console.log('reconnect', reconnect);
                return false;
            }

            this.countdownTokenTime = setTimeout(() => {
                this.reconnectToken();
            }, expireTokenTime - leeway);
        },
        async reconnectToken() {
            try {
                return await this.$store.dispatch('auth/fetchAccessToken')
            } catch (error) {
                this.$store.commit('auth/CLEAR_USER')
                this.$router.push('/login')
            }
        },
    },

    destroyed() {
        window.removeEventListener('resize', this.handleWindowResize)
        window.removeEventListener('scroll', this.handleScroll)

        if (this.countdownTokenTime) {
            clearTimeout(this.countdownTokenTime);
        }
    }
}
</script>

<style lang="scss" scoped></style>