import axios from "../axios";

// state
const state = {
    online: false,
    list: [],
    meta: {},
    filter: {
        page: 1,
        per_page: 10,

        search: ``,
        category_id: ``,
    },

    activity: {
        status: ``,
        isLoad: false,
        isError: false,
        isMode: false,
        isEmpty: false,
    },

    options: undefined,
};

// getters
const getters = {
    isActive: (state) => state.online,
    list: (state) => state.list,
    meta: (state) => state.meta,
    filter: (state) => state.filter,
    activity: (state) => state.activity,

    options: (state) => state.options,
};

// mutations
const mutations = {
    SET_ONLINE(state, active) {
        state.online = active;
    },
    SET_LIST(state, list) {
        state.list = [...state.list, ...list];
    },
    RESET_LIST(state, list) {
        state.list = [];
    },
    SET_META(state, meta) {
        state.meta = { ...state.meta, ...meta };
    },
    SET_FILTER(state, filter) {
        state.filter = { ...state.filter, ...filter };
    },
    SET_ACTIVITY(state, activity) {
        state.activity = { ...state.activity, ...activity };
    },
    SET_OPTIONS(state, options) {
        state.options = options;
    },
};

// actions
const actions = {
    async getById({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/health/${id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    async fetch({ commit }, filter) {
        if (filter.page == 1) {
            commit("RESET_LIST");
        }

        return new Promise((resolve, reject) => {
            axios
                .get("/health", { params: filter })
                .then((response) => {
                    const res = response.data;

                    commit("SET_LIST", res.data);
                    commit("SET_META", res.meta);

                    commit("SET_ACTIVITY", {
                        isEmpty: res.meta.total == 0 && filter.page == 1,
                        isMode: res.links.next ? true : false,
                    });

                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    // filter
    async setFilter({ commit }, filter) {
        commit("SET_FILTER", filter);
    },

    // activity
    async setActivity({ commit }, activity) {
        commit("SET_ACTIVITY", activity);
    },

    // options
    async fetchOptions({ commit }) {
        return new Promise((resolve, reject) => {
            axios
                .get("/health/options")
                .then((response) => {
                    const res = response.data;
                    commit("SET_OPTIONS", res.data);
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    async update({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/health", payload)
                .then((response) => {
                    commit("SET_ONLINE", false);
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
