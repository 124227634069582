import axios from "../../axios";

// state
const state = {};

// getters
const getters = {};

// mutations
const mutations = {};

// actions
const actions = {
    async create({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post("/appointment", payload)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    async getById({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/appointment/${id}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
