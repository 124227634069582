import Vue from "vue";
import Vuex from "vuex";

import state from "./state";
import getters from "./getters";
import mutations from "./mutations";
import actions from "./actions";

Vue.use(Vuex);

import moduleAuth from "./auth/moduleAuth.js";
import moduleUi from "./ui/index.js";
import moduleRegigter from "./regigter/index.js";
import appointment from "./appointment/appointment.js";
import hotline from "./hotline/hotline.js";
import knowledge from "./knowledge.js";
import marketplace from "./marketplace.js";
import member from "./member.js";
import assessment from "./assessment.js";
import health from "./health.js";
import schedule from "./schedule.js";

export default new Vuex.Store({
    getters,
    mutations,
    state,
    actions,
    modules: {
        auth: moduleAuth,
        ui: moduleUi,
        regigter: moduleRegigter,
        appointment,
        hotline,
        knowledge,
        marketplace,
        member,
        assessment,
        health,
        schedule,
    },
    strict: process.env.NODE_ENV !== "production",
});
