import axios from "../../axios";

// state
const state = {
    isInit: false,
    villages: [],
};

// getters
const getters = {
    isInit: (state) => state.isInit,
};

// mutations
const mutations = {
    setInit(state, isInit) {
        state.isInit = isInit;
    },
};

// actions
const actions = {
    async init({ state, commit }) {
        if (state.isInit) {
            return;
        }

        try {
            const { data: response } = await axios.get("/register/init");

            if (response) {
                commit("setInit", true);
                return response;
            }
        } catch (error) {}

        return null;
    },

    async create({ state, commit }, payload) {
        try {
            const { data: response } = await axios.post("/register", payload);
            return response;
        } catch (error) {
            throw error;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
