// require("./bootstrap");

import Vue from "vue";
import App from "./App.vue";

import axios from "./axios.js";
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
import store from "./store/store.js";
import router from "./router";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import VueMask from "v-mask";
import FormatterPlugin from './plugins/formatter'

Vue.prototype.$http = axios;
Vue.use(VueSweetalert2);
Vue.use(VueMask);
Vue.use(FormatterPlugin)
Vue.config.productionTip = false;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
