import axios from "../axios";

// state
const state = {
    isOnline: false,
    list: [],
    meta: {},
    filter: {
        page: 1,
        per_page: 10,
    },
    activity: {
        status: ``,
        isLoad: false,
        isError: false,
        isMode: false,
        isEmpty: false,
    },
};

// getters
const getters = {
    isActive: (state) => state.isOnline,
    list: (state) => state.list,
    meta: (state) => state.meta,
    filter: (state) => state.filter,
    activity: (state) => state.activity,

    get: (state) => (id) => {
        return state.list.find((item) => item.id == id);
    },
};

// mutations
const mutations = {
    SET_ONLINE(state, active) {
        state.online = active;
    },

    SET_LIST(state, list) {
        state.list = [...state.list, ...list];
    },
    RESET_LIST(state) {
        state.list = [];
    },

    SET_META(state, meta) {
        state.meta = meta;
    },

    SET_FILTER(state, filter) {
        state.filter = filter;
    },

    SET_ACTIVITY(state, activity) {
        state.isOnline = activity;
    },
};

// actions
const actions = {
    getAssessment({ commit }, filter) {
        if (filter.page == 1) {
            commit("RESET_LIST");
        }

        return new Promise((resolve, reject) => {
            axios
                .get(`/assessment`, {
                    params: filter,
                })
                .then((response) => {
                    const res = response.data;

                    commit("SET_LIST", res.data);
                    commit("SET_META", res.meta);

                    commit("SET_ACTIVITY", {
                        isEmpty: res.meta.total == 0 && filter.page == 1,
                        isMode: res.links.next ? true : false,
                    });

                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    setFilter({ commit }, payload) {
        commit("SET_FILTER", payload);
    },

    setActivity({ commit }, payload) {
        commit("SET_ACTIVITY", payload);
    },

    getById({ commit }, id) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/assessment/${id}`, {
                    params: {
                        filters: `questions,criterias`,
                    },
                })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    sentAssessment({ commit }, payload) {
        return new Promise((resolve, reject) => {
            axios
                .post(
                    `/assessment/${payload.assessment_id}/registration`,
                    payload
                )
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },

    getCheckout({ commit }, checkoutId) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/assessment/checkout/${checkoutId}`)
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
    getHistory({ commit }, filter) {
        return new Promise((resolve, reject) => {
            axios
                .get(`/assessment/history`, { params: filter })
                .then((response) => {
                    resolve(response);
                })
                .catch((error) => reject(error));
        });
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
