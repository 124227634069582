import Vue from "vue";
import Router from "vue-router";
import auth from "./auth/authService";

Vue.use(Router);

import Layout from "./layouts/application.vue";

const router = new Router({
    mode: "history",
    routes: [
        {
            path: "/",
            component: Layout,
            children: [
                {
                    path: "/",
                    component: () => import("./views/home/index.vue"),
                },
                {
                    path: "/welcome",
                    component: () => import("./views/welcome/index.vue"),
                },
                {
                    path: "/login",
                    component: () => import("./views/auth/login.vue"),
                },
                {
                    path: "/register",
                    component: () => import("./views/auth/register.vue"),
                },

                /** knowledge */
                {
                    path: "/knowledge",
                    component: () => import("./views/knowledge/index.vue"),
                    meta: {},
                },
                {
                    path: "/knowledge/:id",
                    component: () => import("./views/knowledge/detail.vue"),
                    meta: {},
                },

                /** marketplace */
                {
                    path: "/marketplace",
                    component: () => import("./views/marketplace/index.vue"),
                    meta: {},
                },
                {
                    path: "/marketplace/category/:id",
                    component: () => import("./views/marketplace/category.vue"),
                    meta: {},
                },
                {
                    path: "/marketplace/:id",
                    component: () => import("./views/marketplace/detail.vue"),
                    meta: {},
                },

                /**
                 * profile
                 */
                {
                    path: "/profile",
                    component: () => import("./views/profile/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/general",
                    component: () => import("./views/profile/general.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/contact",
                    component: () => import("./views/profile/contact.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/education",
                    component: () => import("./views/profile/education.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/health",
                    component: () => import("./views/profile/health/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/health/history",
                    component: () =>
                        import("./views/profile/health/history.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/health/update",
                    component: () =>
                        import("./views/profile/health/update.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/profile/health/:id",
                    component: () =>
                        import("./views/profile/health/detail.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },

                /** help */
                {
                    path: "/help",
                    component: () => import("./views/help/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/help/appointment",
                    component: () =>
                        import("./views/help/appointment/index.vue"),
                },
                {
                    path: "/help/appointment/:id",
                    component: () =>
                        import("./views/help/appointment/detail.vue"),
                },
                {
                    path: "/help/line-chat",
                    component: () => import("./views/help/line-chat.vue"),
                },

                /** assessment */
                {
                    path: "/assessment",
                    component: () => import("./views/assessment/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/assessment/history",
                    component: () => import("./views/assessment/history.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/assessment/checkin/:id",
                    component: () => import("./views/assessment/checkin.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/assessment/bmi",
                    component: () => import("./views/assessment/bmi.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/assessment/:id",
                    component: () => import("./views/assessment/form.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/assessment/:id/thank-you",
                    component: () => import("./views/assessment/thank-you.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },

                /** hotline */
                {
                    path: "/hotline",
                    component: () => import("./views/hotline/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },

                /** schedule */
                {
                    path: "/schedule",
                    component: () => import("./views/schedule/index.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
                {
                    path: "/schedule/:id",
                    component: () => import("./views/schedule/detail.vue"),
                    meta: {
                        requiresAuth: true,
                    },
                },
            ],
        },

        {
            path: "*",
            component: () => import("./views/errors/404.vue"),
        },
    ],
});

router.afterEach(() => {
    // console.log("router afterEach");
});

router.beforeEach((to, from, next) => {
    // console.log("router beforeEach:", to.path);

    if (
        to.path === "/welcome" ||
        to.path === "/login" ||
        to.path === "/register" ||
        to.path === "/forgot-password" ||
        to.path === "/error-404" ||
        to.path === "/error-500" ||
        to.path === "/callback"
    ) {
        return next();
    }

    if (!auth.isAuthenticated()) {
        router.push({ path: "/welcome", query: { to: to.path } });
    }

    return next();
});

export default router;
