export default {
    isLoggedIn(state) {
        return state.isLoggedIn;
    },
    user(state) {
        return state.user;
    },
    expiresAt(state) {
        return state.expiresAt;
    }
}