import axios from "../../../axios/index.js";

// Token Refresh
let isAlreadyFetchingAccessToken = false;
let subscribers = [];

function onAccessTokenFetched(access_token) {
    subscribers = subscribers.filter((callback) => callback(access_token));
}

function addSubscriber(callback) {
    subscribers.push(callback);
}

export default {
    init() {
        // Add request interceptor
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                const { config, response } = error;
                const originalRequest = config;

                return Promise.reject(error);
            }
        );
    },
    login(payload) {
        return axios.post("/login", payload);
    },
    refreshToken() {
        return axios.post("/auth/refresh-token");
    },
    logout() {
        return axios.post("/auth/logout");
    },
    getUser() {
        return axios.get("/auth/user");
    },
};
