import auth from "../../auth/authService";

export default {
    user: null,
    isLoggedIn: false,
    isUserLoggedIn: () => {
        let isAuthenticated = false;

        if (auth.isAuthenticated()) {
            isAuthenticated = true;
        }

        return isAuthenticated;
    },

    // Check if user is logged in by default from localStorage
    expiresAt: localStorage.getItem("tokenExpiry") || null,
};
