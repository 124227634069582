/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import axios from "../../http/axios/index.js";

export default {
    SET_BEARER(state, accessToken) {
        axios.defaults.headers.common[
            "Authorization"
        ] = `Bearer ${accessToken}`;
    },
    UPDATE_TOKEN(state, payload) {
        const expDate = new Date();
        expDate.setSeconds(expDate.getSeconds() + payload.tokenExpiry);

        localStorage.setItem("tokenExpiry", expDate);
        localStorage.setItem("loggedIn", "true");

        state.expiresAt = expDate;
    },
    SET_USER(state, user) {
        state.user = user;
        state.isLoggedIn = true;
    },
    CLEAR_USER(state) {
        // Remove accessToken from localStorage
        localStorage.removeItem("accessToken");

        // Remove user from localStorage
        localStorage.removeItem("loggedIn");

        // Remove token expiry
        localStorage.removeItem("tokenExpiry");

        // Remove user from localStorage
        localStorage.removeItem("user");

        state.user = null;
        state.isLoggedIn = false;
    },

    UPDATE_LOGGED_IN(state, value) {
        state.isLoggedIn = value;
    },
};
