import store from "../store/store.js";

// 'loggedIn' is used in other parts of application. So, Don't forget to change there also
const localStorageKey = "loggedIn";
const tokenExpiryKey = "tokenExpiry";
const loginEvent = "loginEvent";

class AuthService {
    idToken = null;
    profile = null;
    tokenExpiry = null;

    // Starts the user login flow
    login(customState) {}

    // Starts the user login flow
    localLogin(authResult) {}

    logOut() {}

    isAuthenticated() {
        const now = new Date(Date.now());
        const expireDate = new Date(localStorage.getItem(tokenExpiryKey));

        const time = 3600000;
        const isExpire = now < expireDate;
        if (!isExpire) {
            store.dispatch("auth/updateLoggedIn", false);
        }

        return isExpire;
    }

    renewTokens() {
        return new Promise((resolve, reject) => {
            store
                .dispatch("auth/fetchUser")
                .then((user) => {
                    resolve(user);
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
}
export default new AuthService();
